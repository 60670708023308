const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6965421",
    space_js: "//h5fpb1.szcgsoft.cn/production/lg_fo_fd_cv/source/p.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6965423",
    space_js: "//h5fpb1.szcgsoft.cn/production/ni/static/h/qhfe_x/a.js"
  }
];

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6965424",
    space_js: "//h5fpb1.szcgsoft.cn/common/oji_r_ig_fy/static/f.js"
  }
];

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6965425",
    space_js: "//h5fpb1.szcgsoft.cn/common/pk_jsj/production/h_g_zh.js"
  }
];

const Banner = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6965422",
    space_js: "//h5fpb1.szcgsoft.cn/source/m_hg/pg/resource/e_dww.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native1,
  Home_banner: Banner,
  Detail_inter: Inter,
  Detail_native: Native1,
  Detail_banner: Banner,
  List_native: Native1,
  List_banner: Banner,
  Result_inter: Inter,
  Result_native: Native1,
  Result_banner: Banner,
  Result_native_center1: Native2,
  Result_native_center2: Native3,
};
